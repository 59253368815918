@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal
	font-display: swap

$icon-calendar-check: "\EA01"
$icon-contact: "\EA02"
$icon-logout: "\EA03"
$icon-date: "\EA04"
$icon-arrow-left: "\EA05"
$icon-arrow-right: "\EA06"
$icon-edit: "\EA07"
$icon-sheet: "\EA08"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-calendar-check:before
	content: $icon-calendar-check
.icon-contact:before
	content: $icon-contact
.icon-logout:before
	content: $icon-logout
.icon-date:before
	content: $icon-date
.icon-arrow-left:before
	content: $icon-arrow-left
.icon-arrow-right:before
	content: $icon-arrow-right
.icon-edit:before
	content: $icon-edit
.icon-sheet:before
	content: $icon-sheet
